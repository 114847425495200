import * as React from "react"
import styled from "styled-components";


import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';
import Image from '../components/Image';

const Box = styled.div`
  @media(max-width: 1000px){
    padding: 0 20px;
  }
`;

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;

  p{
    margin-right:20px;
  }
  h4 {
    margin-left: 10px;
  }
  .image-icon{
    height: 31px;
    width: 31px;
  }
  .image-aseo{
    height: 232px;
    width: 242px;
  }
  .featurette-SubTitulo {
    font-size: 24px;
  }
  @media(max-width: 1000px){
    width: 100%;
  }
`;

const ContainerBox = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;
  justifyContent: space-between;
  @media(max-width: 1000px){
    flex-direction: column;
    justifyContent: flex-start;
    align-items: flex-start;
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Láminas de Seguridad" />
    <Box>
      <Title title='Láminas' secondTitle='de Seguridad' />
        <span>Las láminas de seguridad son básicamente una película transparente o tinteada, que se coloca pegada al vidrio en su lado que da al interior del automóvil. Su efecto busca aumentar la resistencia del vidrio a daños por objetos que lo golpeen, a base de ejercer una presión hacia afuera, la que absorba la energía de un golpe.
          <br/>El efecto ideal se logra gracias a su pegamento, el cual al fraguar por completo, se contrae y aumenta la presión que se ejerce hacia afuera, aumentando su resistencia a golpes en dirección hacia el interior del vehículo o vidrio de casa y oficina . Por otro lado, una lamina que esta bien instalada debería ser fácil de sacar al golpear el vidrio desde el interior hacia afuera, el cual permite el escape del vehículo o vivienda en alguna situación de emergencia, como un choque o siniestro.
        </span>
        <span><br/><br/>La lamina una vez instalada, en caso de romperse, mantiene todos los fragmentos del vidrio juntos, lo que protege a los ocupantes del vehículo de los posibles fragmentos de vidrio que pudieran ingresar al vehículo o a la vivienda, minimizando los daños producidos en caso de llegar a romperse.
          <br/>Otra de las grandes ventajas de las laminas de seguridad, es que según sus características y calidad, permiten la filtración de hasta un 99% de los rayos UV que ingresan al interior del vehículo, mejorando mucho la vida al interior en días de calor, y aumentando la eficiencia del aire acondicionado. También, mejoran la insonorización al interior , al aumentar el grosor del vidrio.
        </span>
        <span><br/><br/>Las laminas de seguridad de esov estan certificadas por normas internacionales , son de origen EEUU
        </span>
      
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Visitenos y podremos orientarlo Técnicamente para dar la mejor solución a su requerimiento" />
          <h4 class="featurette-SubTitulo">Visitenos y podremos orientarlo Técnicamente para dar la mejor solución a su requerimiento</h4>
        </Container>

    
      <Container style={{ justifyContent: 'center', width: "100%", justifyContent: 'space-between' }}>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_1.png" width="242" height="231"/>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_2.png" width="242" height="231"/>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_3.png" width="242" height="231"/>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_4.png" width="242" height="231"/>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_5.png" width="242" height="231"/>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_6.png" width="242" height="231"/>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_7.png" width="242" height="231"/>
        <Image classNamee="image-aseo" alt="Láminas de seguridad" srcName="laminas_8.png" width="242" height="231"/>
      </Container>
    </Box>
  </Layout>
)

export default IndexPage
    
  
    